import { useState } from "react";
import { Repair } from "../services/user";
import { useEffect } from "react";
import { SendVerificationlink } from "../utils/functions";
import Spinner from "../components/generic/Spinner";

export default () => {
  const [costline, setlacoste] = useState([]);
  const [isloading, setisloading] = useState(false);
  const getnotverrusers = async () => {
    var res = await Repair();
    console.log(res);

    setlacoste(res);
  };

  useEffect(() => {
    getnotverrusers();
  }, []);
  return (
    <div>
      <Spinner loading={isloading} />
      {costline?.map((item) => {
        return <p>,{item}</p>;
      })}
      eeeeewwwww
      <button
      className="p-3 bg-blue-300 text-white "
        onClick={async () => {
          setisloading(true);
          await Promise.all(
            costline.map(async (item) => {
              await SendVerificationlink(item.email, item.firstname);
              console.log("Sent for", item.firstname, item.email);
            })
          );

          setisloading(false);
        }}
      >
        Send emails
      </button>
    </div>
  );
};
