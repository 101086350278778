const user = localStorage.getItem(`UserData`);

const userobj = JSON.parse(user ?? "{}");
console.log(userobj);

const default_state = {
  ui: { pagetitle: "" },
  selectedSchool: {},
  authuser: userobj,
  userschool: {},
  isAdminAuth: false,
  Govstaffs: [],
  facilities: [],
  accreditedStatus: "",
};

const reducer = (state = default_state, action) => {
  switch (action.type) {
    case "set-selectedSchool":
      return { ...state, selectedSchool: { ...action.payload } };

    case "set-accred":
      return { ...state, accreditedStatus: action.payload };
    case "set-adminauth":
      return { ...state, isAdminAuth: action.payload };

    case "set-facilities":
      return { ...state, facilities: action.payload };
    case "set-govstaffs":
      return { ...state, Govstaffs: action.payload };
    case "set-govs":
      return { ...state, Govs: action.payload };

    case "set-user-school":
      return { ...state, userschool: action.payload };
    case "set-auth-user":
      return { ...state, authuser: action.payload };

    case "set-page-title":
      return { ...state, ui: { ...state.ui, pagetitle: action.payload } };

    default:
      return { ...state };
  }
};

export { reducer };
