import { useNavigate } from "react-router-dom";
import {
  NGcoatofam,
  coatofarm,
  hedgelogo,
  logo,
  logo1,
  logo2,
  logo3,
  logo4,
} from "../constants/imports";
import Button from "../components/generic/Button";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useEffect } from "react";

const Welcome = () => {
  var navigate = useNavigate();
useEffect(()=>{

  window.location.href ="https://schoolversedata.com"
},[])
  return (
    <div className="bg-primary flex justify-center h-[100vh] items-center">
    <div className="bg-white md:min-w-[30vw] rounded   p-10 flex  items-center flex-col ">
      <img className="mb-6 w-40 m-2" src={logo} />
<p className=" mb-4 text-xs font-semibold text-[18px]">
        Welcome to SchoolVerse
      </p>  

      <div className=" flex justify-between my-4 mb-6 ">
        <img className="w-10 m-2" src={logo1} />
        <img className="w-10 m-2" src={logo4} />
        <img className="w-10 m-2" src={logo2} />
        <img className="w-10 m-2" src={logo3} />
       
      </div>
      <p className="text-primary   font-semibold ">     Welcome to SchoolVerse</p>
      <p className="self-start font-semibold text-xs mt-2  text-primary ">
    
        For Schools
      </p>
      <div className=" w-full   justify-between items-center grid grid-cols-2 gap-4">
        <Button
          text="Create account"
          onBtnClick={() => {
            navigate("/signup");
          }}
        />
        <Button
          text="Login"
          onBtnClick={() => {
            navigate("/login");
          }}
        />
      </div>
<p className="self-start text-primary font-semibold underline text-xs ">
        <a href="https://staff.schoolversedata.com/">
          For School Staffs <FaExternalLinkAlt className="inline" />
        </a>
      </p> 
<p className="self-start text-primary  font-semibold underline text-xs mt-3 "> <a href ="http://gov.schoolversedata.com" >For Government Staffs <FaExternalLinkAlt className="inline" /></a></p>
     
    </div>
  </div>
  );
};

export { Welcome };

{
  /* 
<div className="bg-primary flex justify-center h-[100vh] items-center">
      <div className="bg-white md:min-w-[30vw] rounded   p-10 flex  items-center flex-col ">
        <img className="mb-6 w-40 m-2" src={logo} />
 <p className=" mb-4 text-xs font-semibold text-[18px]">
          Welcome to SchoolVerse
        </p>  

        <div className=" flex justify-between my-4 mb-6 ">
          <img className="w-10 m-2" src={logo1} />
          <img className="w-10 m-2" src={logo4} />
          <img className="w-10 m-2" src={logo2} />
          <img className="w-10 m-2" src={logo3} />
         
        </div>
        <p className="text-primary   font-semibold ">     Welcome to SchoolVerse</p>
        <p className="self-start font-semibold text-xs mt-2  text-primary ">
      
          For Schools
        </p>
        <div className=" w-full   justify-between items-center grid grid-cols-2 gap-4">
          <Button
            text="Create account"
            onBtnClick={() => {
              navigate("/signup");
            }}
          />
          <Button
            text="Login"
            onBtnClick={() => {
              navigate("/login");
            }}
          />
        </div>
  <p className="self-start text-primary font-semibold underline text-xs ">
          <a href="https://staff.schoolversedata.com/">
            For School Staffs <FaExternalLinkAlt className="inline" />
          </a>
        </p> 
  <p className="self-start text-primary  font-semibold underline text-xs mt-3 "> <a href ="http://gov.schoolversedata.com" >For Government Staffs <FaExternalLinkAlt className="inline" /></a></p>
       
      </div>
    </div> */
}
