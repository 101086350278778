import axios from "axios";
import { baseURL } from "../utils/URL";

const onUpdatePaymentinfo = async (Postdata) => {
  try {
    const response = await axios.post(`${baseURL}/payment/add`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetPaymentinfo = async (Postdata) => {
  try {
    const response = await axios.post(`${baseURL}/payment/get`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetPayments = async (Postdata) => {
  try {
    const response = await axios.get(`${baseURL}/getpayment`, Postdata);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
export { onUpdatePaymentinfo, onGetPayments,onGetPaymentinfo };
