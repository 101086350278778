import logo from "./logo.svg";
import "./index.css";
import "./output.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Welcome } from "./pages/Welcome";
import { Dashboard } from "./pages/Dashboard";
import { RegisterSchool } from "./components/Dashboard/RegisterScgool";
import { Provider } from "react-redux";
import { store } from "./state/store/store";
import { Facilities } from "./pages/ProtectedRoutes/Facilities";
import { Fees } from "./pages/ProtectedRoutes/Fees";
import { Payments } from "./pages/ProtectedRoutes/Payment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AccountDashbaord } from "./pages/ProtectedRoutes/AccountDashbaord";
import { Rating } from "./pages/ProtectedRoutes/Rating";
import { Settings } from "./pages/ProtectedRoutes/Settings";
import { Admin } from "./pages/ProtectedRoutes/Admin";
import { Mobile } from "./pages/Mobile";
import { SelectedSchool } from "./components/Admin/SeclectedSchool";
import { AdminDashbaord } from "./components/Admin/Dashboard";
import { Verify } from "./pages/Verify";
import { Requestchange } from "./pages/RewuestReset";
import { ResetPass } from "./pages/ResetPass";
import { Map } from "./pages/ProtectedRoutes/Map";
import { Review } from "./pages/ProtectedRoutes/Review";
import { Certificates } from "./pages/ProtectedRoutes/Certificates";
import { Certificates as Admincerview } from "./components/Admin/Certificates";
import { Admin_Fees } from "./components/Admin/Fees/Fees_Admin";
import { AdminFacilities } from "./components/Admin/Facility/AdminFacility";
import { MapAdmin } from "./components/Admin/MapAdmin";
import ClassTeacher from "./pages/ProtectedRoutes/ClassTeacher";
import Notificatons from "./pages/ProtectedRoutes/Notificatons";
import EditSchoolDetails from "./pages/ProtectedRoutes/EditSchoolModal";
import GovStaffPortal from "./routes/GovStaffPortal";
import GovPortal from "./routes/GovPortal";
import StaffPortal from "./routes/StaffPortal";
import Govstaffs from "./components/Admin/Govstaffs";
import Govs from "./components/Admin/Govs";
import Staff from "./pages/ProtectedRoutes/Staff";
import SchoolDisplay from "./pages/SchoolDisplay";
import Repair from "./pages/Repair";

function App() {
  return (
    <Provider store={store}>
      <Router
      

      >
        <ToastContainer
          toastStyle={{ backgroundColor: "white", color: "black" }}
        />

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/schooldisplay" element={<SchoolDisplay />} />
          <Route path="/resetpassword" element={<Requestchange />} />
          <Route path="/repxxx" element={<Repair />} />
          <Route path="/changepass/:userId" element={<ResetPass />} />
          <Route path="/verify/:userId" element={<Verify />} />
          <Route path="/admin" element={<AdminDashbaord />}>
            <Route path="dashboard" element={<Admin />} />
            <Route path="" element={<Admin />} />
            <Route path="school" element={<SelectedSchool />} />
            <Route path="certificates" element={<Admincerview />} />
            <Route path="fees" element={<Admin_Fees />} />
            <Route path="facilities" element={<AdminFacilities />} />
            <Route path="payments" element={<Payments />} />
            <Route path="govstaffs" element={<Govstaffs />} />
            <Route path="govs" element={<Govs />} />
            <Route path="mapadmin" element={<MapAdmin />} />
          </Route>
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<AccountDashbaord />} />
            <Route path="registerschool" element={<RegisterSchool isedit={false}/>} />
            <Route path="notifications" element={<Notificatons />} />
            <Route path="facilities" element={<Facilities />} />
            <Route path="staff" element={<Staff />} />
            <Route path="rating" element={<Rating />} />
            <Route path="review" element={<Review />} />
            <Route path="map" element={<Map />} />
            <Route path="fees" element={<Fees />} />
            <Route path="classteacher" element={<ClassTeacher />} />
            <Route path="certificates" element={<Certificates />} />
            <Route path="accountdashboard" element={<AccountDashbaord />} />
            <Route path="payments" element={<Payments />} />
            <Route path="settings" element={<Settings />} />
            <Route path="editschool" element={<EditSchoolDetails />} />
          </Route>

          <Route path="/" element={<Welcome />} />
          {/* <Route path="/govstaff" element={<GovStaffPortal />} />
          <Route path="/gov" element={<GovPortal />} />
          <Route path="/staff" element={<StaffPortal />} /> */}
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
