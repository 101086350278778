import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminHero } from "../../components/Admin/AdminHero";
import SchoolItem  from "../../components/Admin/SchoolItem";
import Button from "../../components/generic/Button";
import { onExportAlldata, onGetAllSchool } from "../../services/school";
import { onGetAllstaffs, onGetAllusers } from "../../services/user";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/generic/Spinner";
import { MdDelete, MdRefresh } from "react-icons/md";
import SchoolSearchBar from "../../components/generic/SchoolSearchBar";
import { FaEnvelopesBulk } from "react-icons/fa6";
import { onGetPayments } from "../../services/payment";

const Admin = () => {
  const [schools, setschools] = useState([]);
  const [users, setusers] = useState([]);
  const school_ = useSelector((state) => state.selectedSchool);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalschool, settotalschools] = useState();
  const [totalusers, settotalusers] = useState();
  const [renderedschools, setrenderedschools] = useState([]);
  const [psyments, setpayments] = useState([]);
  const [showbulk, setshowbulk] = useState(false);

  const [searchValue, setSearchValue] = useState();
  const onserachChange = (e) => {
    setSearchValue(e);

    setrenderedschools(
      schools.filter((i) => {
        console.log(e, i);
        return i.schoolname?.toLowerCase().includes(e.toLowerCase());
      })
    );
  };
  useEffect(() => {
    setrenderedschools(schools);
  }, [schools]);

  const getPayments = async () => {
    var res = await onGetPayments();

    var emptearr = [];
    for (let school in res.payment) {
      console.log(school);
      emptearr.push(school);
    }

    setpayments(emptearr);
  };
  const getallschools = async () => {
    var res = await onGetAllSchool();

    settotalschools(Object.keys(res).length);
    var schoolsarray = [];
    for (let item in res) {
      schoolsarray.push(res[item]);
    }

    setschools(schoolsarray);
  };

 
  const getallStaffusers = async () => {
    var res = await onGetAllstaffs();

    settotalusers(Object.keys(res).length);
    var usersarray = [];
    for (let item in res) {
      usersarray.push(res[item]);
    }

    setusers(usersarray);
  };

  const dispatcher = useDispatch();

  dispatcher({ type: "set-page-title", payload: "Admin panel" });

  const onExport = async () => {
    setLoading(true);
    let postData = {
      data: schools,
      school: "all",
    };
    var res = await onExportAlldata(postData);
    setLoading(false);
  };
  
  useEffect(() => {
    
    getallschools();
    getPayments();
    getallStaffusers();

  }, []);
  return (
    <div className="">
      <Spinner loading={loading} />
      <AdminHero totalusers={totalusers} totalschools={schools.length} />

      <div className="bg-customGray flex flex-col p-4 m-4">
        
        <div className="flex justify-between bg-white ">
          {/* Search filters */}
          <div className="p-3 bg-white md:flex  w-[70%] items-center mb-4 gap-3">
            <MdRefresh
              onClick={() => {
                getallschools();
              }}
              className=""
            />

            <SchoolSearchBar
              placeholder="Search by name"
              onchange={onserachChange}
              schools={schools}
              psyments={psyments}
              setrenderedschools={setrenderedschools}
              value={searchValue}
            />
            <div className="p-1 cursor-pointer flex flex-col justify-center items-center bg-gray-50 rounded-lg  ">
              <small className=" text-[10px] ">Results</small>

              <p className="font-semibold  ">{renderedschools.length}</p>
              <small className=" text-[9px] ">Bulk Msg.</small>

              <FaEnvelopesBulk
                onClick={() => setshowbulk(true)}
                size={35}
                className="text-primary bg-gray-200 p-1 m-1"
              />
            </div>
          </div>

          <div className="  grid grid-cols-2 gap-3 place-self-end self-end ">
            <Button
              text="Export data"
              onBtnClick={() => {
                onExport();
              }}
            />

            <Button
              outlined
              text="Logout"
              onBtnClick={() => {
                setLoading(true);
                setTimeout(() => {
                  localStorage.clear();
                  window.location.reload();
                  setLoading();
                }, 1500);
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 px-6 gap-6">
          {renderedschools.map((item) => (
            <SchoolItem item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export { Admin };
